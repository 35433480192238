import { useEffect, useRef } from "react";

export default function SuccessModal({
  successText,
  setSuccessModal,
}: Readonly<{
  successText: string;
  setSuccessModal: (successModal: boolean) => void;
}>) {
  const modal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Courtesy of https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
    function handleClickOutside(event: MouseEvent) {
      if (modal.current && !modal?.current.contains(event.target as Node)) {
        setSuccessModal(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal, setSuccessModal]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg" ref={modal}>
        <p className="text-lg font-semibold mb-4">{successText}</p>
      </div>
    </div>
  );
}
